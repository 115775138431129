export const experienceData = [
  {
    id: 1,
    company: "WINGS Pvt Ltd",
    jobtitle: "Frontend Developer",
    startYear: "2020",
    endYear: "2020",
  },
  {
    id: 2,
    company: "Sizh IT Pvt Ltd",
    jobtitle: "Frontend Developer",
    startYear: "2020",
    endYear: "2021",
  },
  {
    id: 3,
    company: "Jazeera Technologies Pvt Ltd",
    jobtitle: "Frontend Developer",
    startYear: "2021",
    endYear: "Present",
  },
];
