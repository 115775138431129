export const educationData = [
  {
    id: 1,
    institution: "R D Inter Collage Gorakhpur",
    course: "Senior Secondary Education",
    startYear: "2013",
    endYear: "2015",
  },
  {
    id: 2,
    institution: "Buddha Institue of Technology",
    course: "Bachelor of Technology",
    startYear: "2016",
    endYear: "2020",
  },
  // {
  //     id: 3,
  //     institution: 'Hawking University, Cambridge',
  //     course: 'Master of Technology',
  //     startYear: '2019',
  //     endYear: 'Present'
  // },
];
